<template>

  <div class="lang-dropdown">
    <div class="current-lang-item" v-on:click="showList">
      <b-img class="flag" :src="currentLocale.img" :alt="currentLocale.locale" />
      <span class="ml-50 text-body">{{currentLocale.name}}</span>
      <b-icon-chevron-down :style="chevronStyle" class="item-icon" />
    </div>

    <div class="dropdown-list" v-if="show">
      <div class="dropdown-lang-item" v-for="localeObj in locales" :key="localeObj.locale"  v-on:click="setLocale(localeObj)">
        <b-img class="flag" :src="localeObj.img" :alt="localeObj.locale" />
        <span class="ml-50">{{localeObj.name}}</span>
      </div>
    </div>
  </div>

</template>

<script>

import {BImg, BIconChevronDown} from 'bootstrap-vue'

export default {
  name: "Language",
  components: {BImg, BIconChevronDown},
  methods: {
    showList() {
      this.show = !this.show;
    },
    setLocale(localeObj) {
      this.$i18n.locale = localeObj.locale
      localStorage.setItem('locale', localeObj.locale)
      this.show = false;
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    chevronStyle() {
      if (this.show) return "transform: scaleY(-1)"
      else return "transform: scaleY(1)"
    }
  },
  data() {
    const locales =[{
      locale: 'en',
      img: require('@/assets/images/2634450_ensign_flag_kingdom_nation_icon.png'),
      name: this.$t('languages.English')
    },{
      locale: 'hu',
      img: require('@/assets/images/2634310_ensign_flag_hungary_nation_icon.png'),
      name: this.$t('languages.Magyar')
    }]

    return {
      locales,
      show: false
    }
  }
}
</script>

<style scoped lang="scss">

  .lang-dropdown {

    float: right;
    margin: 10px 20px 0 0;

    .dropdown-lang-item,
    .current-lang-item {
      cursor: pointer;
    }

    img {
      margin-right: 10px;
      height: 27px;
    }

    .text-body {
      margin-right: 5px;
      line-height: 25px;
    }

    .item-icon {
      transition: all .5s;
    }

    .dropdown-list {
      position: absolute;
      width: 150px;
      top: 50px;
      right: 20px;
      border: 1px solid #bbb;
      background-color: #fff;
      border-radius: 3px;
      //padding: 10px;
    }

    .dropdown-lang-item {
      //margin: 10px 0;
      padding: 10px;
    }

    .dropdown-lang-item:hover {
      background-color: #efefef;
    }
  }

</style>
