<template>
  <div class="login-container">
    <div class="login-bg"></div>

    <div class="form-container">

      <img class="login-logo" src="../../assets/images/logo.png" alt="logo">

      <language />

      <b-form class="form">

        <h2>{{$t('Extras Casting Sms')}}</h2>

        <p>{{$t('Please sign-into your account')}}</p>

        <b-form-group id="username-input-group" :class="{'input-error' : error === true}">
          <div class="input-icon"><b-icon-person /></div>
          <b-form-input id="login-username" type="text" name="username" v-model="username" required :placeholder="$t('form.Username')"/>
        </b-form-group>

        <b-form-group id="password-input-group" :class="{'input-error' : error === true}">
          <div class="input-icon"><b-icon-key /></div>
          <b-form-input id="login-password" type="password" name="password" v-model="password" required :placeholder="$t('form.Password')"/>
        </b-form-group>

        <p class="form-error" v-if="error">{{errorMessage}}</p>

        <b-button class="login-btn" v-on:click="doLogin">{{$t('form.Login')}}</b-button>
      </b-form>

    </div>

  </div>
</template>

<script>

import {BForm, BFormGroup, BFormInput, BIconPerson, BIconKey} from 'bootstrap-vue'
import Language from "@/components/lang/Language";
import axiosIns from "@/libs/axios";

export default {
  name: "Login",
  components: {
    BForm, BFormGroup, BFormInput, BIconPerson, BIconKey,
    Language
  },
  data: () => ({
    username: '',
    password: '',
    error: false,
    errorMessage: ''
  }),
  methods: {
    doLogin() {
      let postObj = {'username': this.username, 'password': this.password}
      axiosIns.post('/login', postObj).then((response) => {
        let result = response.data
        if (result.success === true) {
          this.error = false
          this.$session.start()
          this.$session.set('jwt', result.token)
          this.$session.set('user', result.user)
          this.$router.push({name: 'home-child'})
        } else {
          this.error = true
          this.errorMessage = this.$t(result.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

  .login-container {

      .login-bg {
        background: url("../../assets/images/login-bg.jpg") center no-repeat;
        background-size: cover;
        width: 100%; height: 100%;
        position: absolute;
        top: 0; left: 0;
      }

      .login-bg:after {
        content: "";
        position: absolute;
        background-color: rgba(51, 51, 51, 0.6);
        width: 100%; height: 100%;
        top: 0; left: 0;
      }

      .form-container {
        position: absolute;
        background-color: #f5f5f5;
        width: 500px;
        height: 100%;
        top: 0; right: 0;

        .login-logo {
          height: 150px;
          position: absolute;
          left: -50px;
        }

        .form {
          width: 70%;
          margin: calc((100% - 25px) / 2) auto;

          h2 {
            font-size: 1.2rem;
            text-transform: uppercase;
            text-align: right;
          }

          p {
            text-align: left;
            margin: 20px 0 10px 0;
          }

          .input-icon {
            float: left;
            width: 40px;
            border: 1px solid #2c3e50;
            height: 38px;
            line-height: 38px;
            border-radius: .25rem 0 0 .25rem;
            color: #fff;
            font-size: 1.3rem;
            background-color: #2c3e50;
          }

          input {
            width: calc(100% - 40px);
            margin: 10px 0;
            border-radius: 0 .25rem .25rem 0;
            border: 1px solid #2c3e50;
          }

          input:focus,
          button:focus{
            box-shadow: none;
          }

          .login-btn {
            float: right;
            margin-top: 10px;
            width: 100%;
            background-color: #2c3e50;
            //border-radius: 3px;
          }

          .login-btn:hover {
            background-color: #364b60;
          }

          .input-error {
            .input-icon {
              border: 1px solid #d02e2e;
              background: #d02e2e;
            }

            input {
              border: 1px solid #d02e2e;
            }
          }

          .form-error {
            color: #d02e2e;
            margin: 0 0 10px 0;
            font-size: .8rem;
          }
        }
      }
  }

</style>
